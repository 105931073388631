<template>
  <div class="inventions-interaction">
    <div class="inventions-interaction__container">
      <div class="inventions-interaction__map" @click="tracePosition">
        <div class="inventions-interaction__map-asset" ref="map">
          <img src="assets/img/map.png" alt="map" />
          <div
            v-for="(item, index) in items"
            :key="index"
            class="inventions-interaction__map-marker"
            :class="{
              'inventions-interaction__map-marker--active':
                activeIndex === index
            }"
            :style="{
              left: Math.floor(item.x * 100) + '%',
              top: Math.floor(item.y * 100) + '%'
            }"
            @mouseenter="onMapMarkerMouseover(item, index)"
          ></div>
        </div>
      </div>

      <div class="inventions-interaction__bubbles" ref="bubbles">
          <div class="inventions-interaction__bubble" v-for="(item) in bubbles" :key="item.id" ref="bubble" :id="item.id">
          <span class="inventions-interaction__bubble-year">{{item.content.year}}</span>
          <div class="inventions-interaction__bubble-img">
            <img :src="item.content.img" :alt="item.content.label">
          </div>
          <span class="inventions-interaction__bubble-label">{{item.content.label}}</span>
          <span class="inventions-interaction__bubble-location">{{item.content.location}}</span>
          </div>

        </div>

      <div
        class="inventions-interaction__slider"
        ref="slider"
        @mousemove="onSliderMouseMove"
        @touchmove="onSliderMouseMove"
        @touchstart="onSliderMouseMove"
      >
        <div class="inventions-interaction__slider-bg"></div>
        <div class="inventions-interaction__slider-fill" :style="{ transform: 'scale('+progress+',1)' }" ref="fill"></div>
        <div
          class="inventions-interaction__slider-tooltip"
          :style="{ left: tooltipPosition+'px' }"
        >
          <svg
            id="a"
            data-name="Ebene 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 355 261"
          >
            <path
              d="M247.5,0H107.5C48.13,0,0,48.13,0,107.5H0c0,59.37,48.13,107.5,107.5,107.5h22.5l47.5,46,47.5-46h22.5c59.37,0,107.5-48.13,107.5-107.5h0C355,48.13,306.87,0,247.5,0Z"
              fill="#fff"
            />
          </svg>

          <span>{{ currentYear }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import DownloadHelperMixin from "../../../mixins/DownloadHelperMixin";

export default {
  name: "InnventionsInteraction",
  mixins: [DownloadHelperMixin],
  components: { },
  props: {
    interactionData: undefined
  },
  data() {
    return {
      bubbleID: 0,
      progress: 0,
      activeIndex: 0,
      currentYear:1900,
      items: [],
      tooltipPosition:0,
      bubbles:[]
    };
  },
  methods: {
    deleteBubble(bubble){
      const hideBubbles = this.bubbles.filter(b => b.id === bubble.id);

      hideBubbles.forEach(b =>{
        const index = this.bubbles.indexOf(b);
        this.bubbles.splice(index, 1); // 2nd parameter means remove one item only
      });
    },
    async spawnBubbles(items) {
      this.bubbleID++;

      const bubble = {
        content: items[0],
        visible: true,
        id:'bubble_'+this.bubbleID
      };

      this.bubbles.push(bubble);

      await this.$nextTick;     
      
      const currentBubbles = this.$refs.bubble.slice();
      
      const newBubble = currentBubbles.pop();
      gsap.set(newBubble,{opacity:0,scaleX:.5,scaleY:.5,xPercent:-50,yPercent:-50,left:(bubble.content.x*100)+'%',top:(bubble.content.y*100)+'%',overwrite:true});

      gsap.to(newBubble,{delay:.025,opacity:1,duration:.2});
      gsap.to(newBubble,{ease:"Expo.easeOut",delay:.025,scaleX:1,scaleY:1,left:"50%",top:"55%"});

      currentBubbles.forEach(old =>{
        gsap.to(old,{xPercent:-50,yPercent:-50,overwrite:true,duration:.25,opacity:0,left:"50%",top:"50%",scale:.15}).then(()=>{
          this.deleteBubble(old);
        });
      });
    },
    tracePosition(
      e
    ){
      const mapRect = this.$refs.map.getBoundingClientRect();
      
      const xPercent = Math.max(0,Math.min(1,(e.clientX-mapRect.left)/mapRect.width));
      const yPercent = Math.max(0,Math.min(1,(e.clientY-mapRect.top)/mapRect.height));

      console.log("x:",xPercent);
      console.log("y:",yPercent);
      console.log("");

      },
    createData() {
      let items = this.interactionData.dots.sort((a, b) => {
        return parseInt(a.year) - parseInt(b.year);
      });
      
      const withImage = items.filter(item => item.img !== "");
      
      /*
      const withoutImage = items.filter(item => item.img === "");

      withoutImage.forEach(item =>{
        console.log(item.label + " ("+item.year+")")
      });
*/
      //console.log(withImage.length);

      this.items = withImage;

      this.activeIndex = 0;
      const progress = 0;


      this.currentYear = this.items[0].year;
      gsap.to(this, {
        progress: progress,
        duration: 0.7,
        ease: "Expo.easeOut"
      });
    },
    onSliderMouseMove(e) {
      const slider = this.$refs.slider;
      let x = e.clientX - slider.getBoundingClientRect().left;
      
      
      if(e.touches) {
        e.preventDefault();
x = e.touches[0].clientX - slider.getBoundingClientRect().left;
      }

      const percent = x / (slider.getBoundingClientRect().width);
      this.progress = Math.max(Math.min(percent, 1), 0);
    },
    onMapMarkerMouseover(item, index) {
      this.activeIndex = index;
      const progress = index / (this.items.length - 1);
      gsap.to(this, {
        progress: progress,
        duration: 0.7,
        ease: "Expo.easeOut"
      });

      this.currentYear = item.year;
    },
    onResize() {},
    init() {
      this.onResize();
    }
  },
  watch: {
    currentYear(){
      const items = this.items.filter(item => item.year === this.currentYear);

      
      if(items.length === 2) console.log(this.currentYear);

      this.spawnBubbles(items);
    },
    progress(){
      
      this.activeIndex = Math.round((this.items.length-1)*this.progress);
      
      const active = this.items[this.activeIndex];
      this.currentYear = active.year;

      this.$nextTick(()=>{
        this.tooltipPosition = 4 + this.$refs.fill.getBoundingClientRect().width;
      });
    },
    "$mousePos.lastChange"() {
      const rX = 1 - (this.$mousePos.x / window.innerWidth) * 2;
      const rY = 1 - (this.$mousePos.y / window.innerHeight) * 2;
      gsap.to(this.$refs.map, {
        duration: 3,
        ease: "Expo.easeOut",
        force3D: true,
        rotationX: 30 * rY,
        rotationY: 30 * -rX
      });

      /*const pos = this.$refs.marker.getBoundingClientRect();

            console.log(pos);*/
    },
    stageWidth() {
      this.onResize();
    }
  },
  computed: {
    stageWidth() {
      return this.$screen.width;
    }
  },
  beforeDestroy() {},

  mounted() {
    gsap.from(this.$el, {
      ease: "Expo.easeOut",
      duration: 2,
      scale: 1.2,
      alpha: 0
    });

    this.createData();
  }
};
</script>

<style scoped lang="scss"></style>
